// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.App {
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

    .wrapper {
        width: 90%;
        min-height: 90%;
        margin-top: 40px;
        padding: 20px;
        border: 1px solid #282c341f;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .body-wrapper {
        margin-top: 10px;
        margin-bottom: 50px;

        .body-section {
            .item {
                display: flex;
                flex-direction: row;

                .inner-item {
                    margin: 10px;
                    width: 50%;
                    font-size: medium;

                    label {
                        display: inline-block;
                        margin-right: 10px;
                    }

                    input,
                    textarea,
                    select {
                        background: #eceff18f;
                        border: 1px solid #d8c6c629;
                        color: #000;
                        width: 100%;
                        height: 50px;
                        padding: 10px;
                        border-radius: 3px;
                    }

                    textarea {
                        height: 100px;
                    }

                    .checkbox {
                        height: 20px;
                        width: 20px;
                        margin-top: 5px;
                        cursor: pointer;
                        position: absolute;
                    }

                    select {
                        height: 50px;
                        width: 100%;
                    }
                }
            }
        }

        .condition-block {
            background: #1f222970;
            min-height: 100px;
            border-radius: 3px;

            .condition-wrapper {
                padding: 20px;
                margin: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

                .operation-section {
                    .not-operator {
                        margin-right: 20px;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }

                    .operation-item {
                        background: transparent;
                        color: #080f1d;
                        border: 1px solid #ccc;
                        border-radius: 2px;
                        font-size: medium;
                        height: 40px;
                        width: 200px;
                        margin: 10px 20px 10px 0px;

                        &:hover {
                            background: #e0e0e066 !important;
                            color: #080f1d !important;
                        }


                    }

                    .active {
                        background: #e0e0e066 !important;
                        color: #080f1d;
                    }

                    .remove {
                        background: transparent !important;
                        color: red !important;

                        &:hover {
                            background: red !important;
                            color: #fff !important;
                            border: transparent;
                        }
                    }
                }

                .condition-section {
                    display: flex;
                    flex-direction: row;
                    margin: 10px 10px 10px 0px;

                    .inner-item {
                        display: flex;
                        flex-direction: column;
                        width: 30%;
                        font-size: medium;
                        margin-right: 10px;

                        label {
                            display: inline-block;
                            margin-right: 10px;
                        }

                        input,
                        select {
                            width: 100%;
                            height: 50px;
                            padding: 10px;
                            border-radius: 3px;
                            background: #eceff1a6;
                            border: 1px solid #d8c6c629;
                            color: #000;
                        }

                        option {
                            background: #282c34;
                        }

                    }

                    .event-option {
                        width: 60%;
                    }
                }
            }
        }

        .rule-submit {
            margin: 10px;

            .submit-wrapper {
                text-align: right;

                .rule-submit-btn {
                    color: #080f1d;
                    border: 1px solid #fff;
                    width: 175px;
                    height: 50px;
                    border-radius: 2px;
                    font-size: large;

                    &:hover {
                        background: #fff !important;
                        color: #080f1d !important;
                    }
                }
            }
        }

        .upsell-block {
            display: flex;
            flex-direction: row;
            font-size: medium;
            background: #fff;
            @media (max-width: 768px) {
                flex-direction: column;
            }

            .upsell-item {
                display: flex;
                flex-direction: column;
                width: 50%;
                margin: 10px;
                @media (max-width: 768px) {
                    flex-direction: column;
                    width: 95%;
                    margin: 0px;
                }
                

                select,
                input {
                    width: 100%;
                    height: 50px;
                    padding: 10px;
                    border-radius: 3px;
                    background: #eceff19e;
                    border: 1px solid #d8c6c629;
                    color: #000;
                }

                option {
                    background: #fff;
                }
            }

            .upsell-item.non-tix{
                input{
                    margin-bottom: 5px;
                }
            }
        }

    }

    .date-input::-webkit-calendar-picker-indicator {
        color: #000;
    }

    .manage-rule-wrapper {
        margin-top: 40px;

        .table-head {
            display: flex;
            justify-content: space-between;
            margin: 0px 2px 10px 0px;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .manage-rule-head {
                color: #767676;
            }

            .add-rule-btn {
                padding: 5px 20px;
                border-radius: 2px;
                cursor: pointer;
                font-size: 15px;
                color: #212121;
                background-color: #ddd;
                border: 1px solid #767676;
                margin-left: 5px;

                @media (max-width: 768px) {
                    margin-left: 0px;
                    margin-right: 5px;
                }

                &:hover {
                    background: #fff;
                    color: #080f1d;
                }
            }
        }

        table {
            width: 100%;
            border: 1px solid #767676;
            color: #767676 !important;
            font-size: 15px;

            .edit-rule-btn {
                cursor: pointer;

                &:hover {
                    font-weight: 600;
                }
            }

            .edit-section {
                display: flex;
                justify-content: space-between;
                padding: 10px;

                .cancel-btn {
                    background: transparent;
                    padding: 2px 10px;
                    border: 1px solid #ccc;
                    border-radius: 2px;
                    cursor: pointer;
                    width: 100px;
                    text-align: center;
                    margin-right: 10px;

                    &:hover {
                        border: 1px solid #000;
                        color: #fff;
                        background: #000;
                    }
                }

                .delete-btn {
                    background: transparent;
                    padding: 2px 10px;
                    color: red;
                    border: 1px solid red;
                    border-radius: 2px;
                    cursor: pointer;
                    width: 100px;
                    text-align: center;

                    &:hover {
                        background: red;
                        color: #fff;
                    }
                }
            }

        }

        .table-striped>tbody>tr:nth-of-type(odd)>* {
            color: #fff !important;
        }

        .table-striped>tbody>tr:nth-of-type(even)>* {
            color: #fff !important;
        }

        .table-striped>tbody>tr>td {
            padding: 15px;
        }

        .rule-list-wrapper {
            width: 85vw;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            padding: 20px;

            thead,
            tbody,
            tfoot,
            tr,
            td,
            th {
                padding: 10px;
                border: 1px solid #7676762e;
            }

            .edit-column-wrapper {
                width: 30%;
            }

            .gridContainer {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 10px;

                @media (max-width: 768px) {
                    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                }

                .gridItem {
                    color: #282c34;

                    a {
                        text-decoration: none;
                        color: #ddd;

                        .inner-wrapper {
                            margin: 20px 10px 10px 0px;

                            @media (max-width: 1024px) {
                                margin: 20px 0px 10px 0px;
                            }

                            .image-wrapper {
                                width: 100%;

                                img {
                                    width: 100%;
                                    height: 130px;
                                }
                            }

                            .title {
                                color: #767676;
                                margin-top: 15px;
                                font-size: large;
                            }

                            .sub-title {
                                font-size: smaller;
                                color: #655d5da6;
                            }

                            .type {
                                font-size: small;
                                color: #655d5da6;
                            }

                            .summary {
                                font-size: medium;
                                color: #655d5da6;
                            }


                        }
                    }
                }
            }

            .cart-id-section {
                display: flex;

                .cart-id-inner-wrapper {
                    width: 50%;
                    margin-top: 3px;

                    input {
                        height: 35px;
                        width: 100%;
                        font-size: medium;
                        padding: 5px;
                    }
                }

                .cart-submit {
                    margin: 1px 0px 0px 10px;

                    button {
                        height: 33px;
                    }
                }

                @media (max-width: 1024px) {
                    display: flex;
                    flex-direction: column;

                    .cart-id-inner-wrapper {
                        width: 100%;
                        margin-top: 3px;

                        input {
                            height: 45px;
                            width: calc(100% - 2px);
                            font-size: medium;
                            padding: 5px;
                            border: 1px solid #ccc;
                        }
                    }

                    .cart-submit {
                        margin: 10px 0px 0px 0px;
                        width: 100%;

                        button {
                            height: 33px;
                            width: 100%;
                        }
                    }
                }
            }

        }
    }

    .error {
        border: 1px solid red !important;
    }

    .custom-toast {
        font-size: medium;
    }

    input[type="datetime-local"] {
        color: rgb(0, 0, 0);
    }

    // input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    //     filter: invert(1);
    // }

    .no-rules-found-msg {
        background: transparent;
        height: 100px;
        text-align: center;
        padding-top: 35px;
        border-radius: 3px;
        font-size: medium;
        color: #767171;
    }

    .fetching-data {
        color: #000;
        font-size: medium;
    }
}

body::-webkit-scrollbar {
    display: none !important;
}

.submit-error{
    color: red;
    font-size: 16px;
}