.ReactModal__Overlay {
    position: fixed;
    top: 50%;
    left: 35% !important;
    top: 100px !important;
    // transform: translate(-50%, -50%);
    width: 400px;
    height: 200px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.modal-content {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 20px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

h2 {
    font-size: medium !important;
}

button {
    background-color: red;
    border: 1px solid red;
    border-radius: 2px;
    color: white;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    width: 100px;
    font-size: small !important;

    &:hover {
        color: red;
        background-color: #fff;
        transition: all 1s;
        transform: scale(1.05);
    }
}

.cancel {
    background-color: #4CAF50;
    border: 1px solid #4CAF50;

    &:hover {
        color: #4CAF50;
        background-color: #fff;
        transition: all 1s;
        transform: scale(1.05);
    }
}