// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.App {
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

    .wrapper {
        width: 90%;
        min-height: 90%;
        margin-top: 40px;
        padding: 20px;
        border: 1px solid #282c341f;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        .top-head {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            color: #767676;

            .view-list-btn {
                background: transparent;
                padding: 3px 10px;
                margin-bottom: 6px;
                color: #212121;
                background-color: #ddd;
                border: 1px solid #767676;
                border-radius: 2px;
                cursor: pointer;

                &:hover {
                    background: #fff;
                    color: #080f1d;
                }
            }
        }
    }

    .body-wrapper {
        margin-top: 10px;
        margin-bottom: 50px;

        .body-section {
            .item {
                display: flex;
                flex-direction: row;

                @media (max-width: 768px) {
                    flex-direction: column;
                }

                .inner-item {
                    margin: 10px;
                    width: 50%;
                    font-size: medium;

                    @media (max-width: 768px) {
                        width: 98%;
                    }

                    label {
                        display: inline-block;
                        margin-right: 10px;
                        color: #767676;
                    }

                    input {
                        width: 100%;
                        height: 50px;
                        padding: 10px;
                        border-radius: 3px;
                        background: #eceff194;
                        border: 1px solid #d8c6c629;
                        color: #000;
                    }

                    .checkbox {
                        height: 20px;
                        width: 20px;
                        margin-top: 5px;
                        cursor: pointer;
                        position: absolute;
                    }
                }

                .inner-item.active{
                    padding-top: 10px;
                }
            }
        }

        .condition-block {
            background: #fff;
            min-height: 100px;
            border-radius: 3px;

            .condition-wrapper {
                padding: 20px;
                margin: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

                .operation-section {
                    .operation-item {
                        background: transparent;
                        color: #080f1d;
                        border-radius: 2px;
                        font-size: medium;
                        height: 50px;
                        width: 200px;
                        margin: 10px 20px 10px 0px;
                        border: 1px solid #ccc;

                        @media (max-width: 768px) {
                            width: 85px;
                            margin: 10px 0px 10px 10px;
                        }

                        &:hover {
                            background: #e0e0e066 !important;
                            color: #080f1d !important;
                        }

                    }

                    .active {
                        background: #e0e0e066 !important;
                        color: #080f1d;
                    }

                    .remove {
                        background: transparent !important;
                        color: red !important;

                        @media (max-width: 768px) {
                            width: 90%;
                        }

                        &:hover {
                            background: red !important;
                            color: #fff !important;
                            border: transparent;
                        }
                    }
                }

                .condition-section {
                    display: flex;
                    flex-direction: row;
                    margin: 10px 10px 10px 0px;

                    @media (max-width: 768px) {
                        flex-direction: column;
                        margin: 0px;
                    }

                    .inner-item {
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        font-size: medium;
                        margin-right: 10px;

                        @media (max-width: 768px) {
                            width: 98%;
                        }

                        label {
                            display: inline-block;
                            margin-right: 10px;
                        }

                        input,
                        select {
                            width: 100%;
                            height: 50px;
                            padding: 10px;
                            border-radius: 3px;
                            background: #eceff1a6;
                            border: 1px solid #d8c6c629;
                            color: #000;
                        }

                        option {
                            background: #fff;
                        }

                    }

                    .event-option {
                        width: 60%;

                        @media (max-width: 768px) {
                            width: 98%;
                        }
                    }
                }
            }
        }

        .rule-submit {
            .submit-wrapper {
                text-align: right;

                .rule-submit-btn {
                    background: #ddd;
                    border: 1px solid #767676;
                    width: 175px;
                    height: 50px;
                    color: #212121;
                    border-radius: 2px;
                    font-size: large;

                    &:hover {
                        background: #fff !important;
                        color: #080f1d !important;
                    }
                }
            }
        }

    }

    .error {
        border: 1px solid red !important;
    }

    .custom-toast {
        font-size: medium;
    }

    input[type="datetime-local"] {
        color: rgb(0, 0, 0);
    }

    // input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    //     filter: invert(1);
    // }
}

.logout-btn {
    position: absolute;
    right: 10px;
    margin-top: 10px;
    border: transparent;
    font-size: medium;
    border-radius: 2px;
    background: transparent;
    color: #000;
    padding: 0px 10px 4px 10px;

    &:hover {
        background: #fff;
        color: #080f1d;
    }
}

[data-amplify-authenticator][data-variation=modal] {
    background: #282c34;
    border: transparent;
    padding: 20px;

    .amplify-button--primary {
        background: #282c34;

        &:hover {
            background: #fff;
            color: #282c34;
            border: 1px solid #282c34;
        }
    }
}

.operation-label{
    color: #767676;
    font-size: 18px;
    margin-right: 5px;
    vertical-align: 5px;
}

.upsell-item{
    label{
        color: #767676;
    }
}